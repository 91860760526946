<template>
  <div class="content">
    <h1 class="title title--big title--theme title--indent">Добавление организации</h1>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <app-form-group label="Организация">
            <v-select
              @search="onOrgSearch"
              @input="onSetOrg"
              v-model="org"
              :options="org_options"
              :filterable="false"
              :clearable="true"
              label="value"
              placeholder="Введите название организации или ИНН"
              class="select"
            >
              <template #open-indicator>
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #no-options>Ничего не найдено</template>
              <template #option="option">
                <div class="select__item d-center">{{ option.data.name.full }} ({{ option.data.address.value }})</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.data.name.full }} ({{ option.data.address.value }})</div>
              </template>
            </v-select>
          </app-form-group>
          <app-form-group label-for="name" label="Наименование" required>
            <app-textarea
              v-model="form.value"
              id="name"
              placeholder="Введите наименование"
              :class="{ 'textarea--error': $v.form.value.$error }"
            >
            </app-textarea>
            <template #error>
              <div v-if="$v.form.value.$dirty && !$v.form.value.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for=inn label="ИНН" required>
            <app-input
              v-model.trim="form.inn"
              id="inn"
              placeholder="Введите ИНН"
              :class="{ 'input--error': $v.form.inn.$error }"
            />
            <template #error>
              <div v-if="$v.form.inn.$dirty && !$v.form.inn.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for=kpp label="КПП" required>
            <app-input
              v-model.trim="form.kpp"
              id="kpp"
              placeholder="Введите КПП"
              :class="{ 'input--error': $v.form.kpp.$error }"
            />
            <template #error>
              <div v-if="$v.form.kpp.$dirty && !$v.form.kpp.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="address" label="Адрес" required>
            <app-textarea
              v-model="form.address"
              id="address"
              placeholder="Введите адрес"
              :class="{ 'textarea--error': $v.form.address.$error }"
            >
            </app-textarea>
            <template #error>
              <div v-if="$v.form.address.$dirty && !$v.form.address.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group label-for=branch_type label="Branch type" required>
            <app-input
              v-model.trim="form.branch_type"
              id="branch_type"
              placeholder="Введите branch_type"
              :class="{ 'input--error': $v.form.branch_type.$error }"
            />
            <template #error>
              <div v-if="$v.form.branch_type.$dirty && !$v.form.branch_type.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="full_with_opf" label="Full with opf" required>
            <app-textarea
              v-model="form.full_with_opf"
              id="full_with_opf"
              placeholder="Введите full_with_opf"
              :class="{ 'textarea--error': $v.form.full_with_opf.$error }"
            >
            </app-textarea>
            <template #error>
              <div v-if="$v.form.full_with_opf.$dirty && !$v.form.full_with_opf.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="short_with_opf" label="Short with opf" required>
            <app-textarea
              v-model="form.short_with_opf"
              id="short_with_opf"
              placeholder="Введите short_with_opf"
              :class="{ 'textarea--error': $v.form.short_with_opf.$error }"
            >
            </app-textarea>
            <template #error>
              <div v-if="$v.form.short_with_opf.$dirty && !$v.form.short_with_opf.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button :disabled="$v.form.$error" ref="submit">Добавить организацию</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import {debounce} from 'lodash'
import {required} from 'vuelidate/lib/validators'

export default {
  name: 'CreateOrg',
  data() {
    return {
      form: {},
      org: null,
      org_options: [],
    }
  },
  validations: {
    form: {
      value: { required },
      inn: { required },
      kpp: { required },
      address: { required },
      branch_type: { required },
      full_with_opf: { required },
      short_with_opf: { required },
    }
  },
  methods: {
    onSetOrg() {
      this.form.value = this.org.value
      this.form.inn = this.org.data.inn
      this.form.kpp = this.org.data.kpp
      this.form.address = this.org.data.address.value
      this.form.branch_type = this.org.data.branch_type
      this.form.full_with_opf = this.org.data.name.full_with_opf
      this.form.short_with_opf = this.org.data.name.short_with_opf
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.$store.dispatch('admin/org/POST_ORG', this.form)
        .then(() => {
          this.$refs.submit.preload = false
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Организация добавлена'
          })
          this.$router.push({ name: 'org-list' })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    onOrgSearch(search, loading) {
      loading(true)
      this.searchOrg(loading, search, this)
    },
    searchOrg: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_ORG', search).then(response => {
        vm.org_options = response.data
        loading(false)
      })
    }, 350),
  }
}
</script>
